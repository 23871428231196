<template>
  <v-card
    :elevation="elevation"
    rounded="circle"
    class="pa-0 h-full w-full"
    :width="size"
    :height="size"
    :max-height="size"
    :to="to"
  >
    <v-card-actions class="h-full w-full">
      <logo :size="size" :color="color" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "LogoChip",
  props: {
    elevation: { default: 2 },
    size: { default: "50" },
    to: { type: Object, default: () => ({ name: "index" }) },
    color: { type: String },
  },
};
</script>
